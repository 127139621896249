.navbarWithShadow {
    -webkit-box-shadow: 0px 3px 13px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 3px 13px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 3px 13px 0px rgba(0,0,0,0.25);
}

.navItemContainer {
    font-family: "Mulish";
    letter-spacing: 0.12em;
    font-weight: 300;
    font-size: 20px;
}

.navItem {
    margin: 0 10px 0 10px;
    padding: 0;
    border-bottom: solid .08em transparent;
}

.navItemActive {
    margin: 0 10px 0 10px;
    padding: 0;
    animation: displayUnderline 0.5s ease-in-out forwards;
}

@keyframes displayUnderline {
    from { border-bottom: solid .08em transparent; }
    to { border-bottom: solid .08em white; }
}
.logo {
    font-family: "Mulish";
    letter-spacing: 0.12em;
    font-weight: 400;
    font-size: 32px;

    /* Typewritter animation properties. Based on: https://css-tricks.com/snippets/css/typewriter-effect/ */
    overflow: hidden; /* Ensures the content is not revealed until the animation. */
    width: 0; /* Start hidden. */
    border-right: .08em solid white; /* The typwriter cursor. */
    white-space: nowrap; /* Keeps the content on a single line. */
    animation: /* Animation shorthand reference: https://developer.mozilla.org/en-US/docs/Web/CSS/animation. */
        typing 1.2s 0.8s steps(30, end) forwards,
        blink-caret .8s step-end 6 forwards;
}

.logoDevPortion {
    font-size: 26px;
}

/* The typing effect. */
@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

/* The typewriter cursor effect. */
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: rgb(255, 255, 255, 0.8) }
}